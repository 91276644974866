.nav{
    animation: appear 0.5s ease-out; 
    z-index: 1000;
    width: 35%;
    position: fixed;
    bottom: 15px;
    left: 32.5%;
    color: rgb(0, 0, 0);
    
    .navContainer{
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;
        border-radius: 20px;
        background-color: #ffffff;

        a{
            animation: appearContainer 3s;
            font-size: 1.2rem;
            cursor: pointer;
            transition: 0.3s;
            &:hover{
                color: #8c8c8c;
            }
        }
    }
}

@keyframes appear{
    0%{
        left: 52%;
        max-width: 30px;
        transform: translateY(100%);
    }
    100%{
        left: 32.5%;
        transform: translateY(0);
        max-width: 35%;
    }
}

@keyframes appearContainer{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}