.headerPanel{
    width: 40%;
    height: 100%;
    display: flex;
    background-color: rgb(0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;

    .headerPres{
        color: white;
        font-size: 3em;
        position: absolute;
        top: 20%;
        left: 10%;

        h1{
            animation: SlideInH1 2s;
            letter-spacing: 0.2em;
        }
        h2{
            animation: SlideInH2 3.5s;
            letter-spacing: 0.2em;
        }
    }

    .socialLinks{
        display: flex;
        justify-content: left;
        gap: 40px;
        flex-direction: row;
        animation: SlideInSocialDiv 0.5s;
        position: absolute;
        top: 50%;
        left: 10%;
        background-color: rgb(0, 0, 0);
        border-top: solid white 1px;
        width: 75.5%;
        height: 20%;

        .socialLinkBox{
            display: block;
            margin-top: 20px;
            height: 60px;
            width: 50px;
            animation: LinksAppear 4s ease-in-out;

            .socialLinkIMG{
                animation: float 3s ease-in-out infinite;
                transform: rotateX(30deg) rotateY(-20deg) rotateZ(20deg);
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: transform 0.5s ease; // Transition ajoutée
            }

            .linkShadow {
                border-radius: 50%;
                height: 8px;
                width: 50px;
                background-color: rgb(59, 59, 59);
                opacity: 0.5;
                animation: shadowOpacity 3s ease-in-out infinite;
                transition: opacity 0.5s ease; // Transition ajoutée
            }

            // Arrêter les animations et appliquer les transitions au survol
            &:hover .socialLinkIMG {
                animation: none;
                transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) translateY(0);
                cursor: pointer;
            }

            &:hover .linkShadow {
                animation: none;
                opacity: 0.5;
            }
        }

        @for $i from 1 through 4 {
            .socialLinkBox:nth-child(#{$i}) {
                .socialLinkIMG {
                    animation-delay: #{($i - 1) * 0.5}s;
                }
                .linkShadow {
                    animation-delay: #{($i - 1) * 0.5}s;
                }
            }
        }
    }
}

.bottomHeader{
    position: absolute;
    bottom: 0;
    left:  0;
    width: 100%;
    height: 10%;
    background-color: black;
}

.param {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .paramIMG {
        width: 40px;
        height: 40px;
        transform: rotate(0deg);
        transition: transform 0.5s ease;
    }
    
    &:hover .paramIMG {
        cursor: pointer;
        transform: rotate(180deg);
    }
    
    &:not(:hover) .paramIMG {
        transform: rotate(-180deg);
    }
    
    

    .paramList {
        padding-top: 25px;
        overflow: hidden;
        max-height: 15px;
        width: 25px;
        border-radius: 30px;
        transition: max-height 0.5s ease-out;
        margin: -25.8px 0 0 0;

        p{
            color: rgb(0, 0, 0);
        }
    }

    &:hover .paramList {
        max-height: 200px;
    }
}

@keyframes LinksAppear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes float {
    0% {
        transform: rotateX(30deg) rotateY(-20deg) rotateZ(20deg) translateY(0);
    }
    50% {
        transform: rotateX(30deg) rotateY(-20deg) rotateZ(20deg) translateY(-15px);
    }
    100% {
        transform: rotateX(30deg) rotateY(-20deg) rotateZ(20deg) translateY(0);
    }
}

@keyframes shadowOpacity {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0.5;
    }
}

@keyframes SlideInSocialDiv{
    0%{
        max-width: 0%;
    }
    100%{
        max-width: 75.5%;
    }
}

@keyframes SlideInH1{
    0%{
        opacity: 0;
        transform: translateY(300%);
    }
    100%{
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes SlideInH2{
    0%{
        opacity: 0;
        transform: translateY(160%);
    }
    100%{
        opacity: 1;
        transform: translateY(0%);
    }
}

