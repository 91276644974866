*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    font-family: 'Roboto', sans-serif;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
}

section, footer{
    min-height: 100vh;
}